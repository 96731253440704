<template>
  <dialogComp
    title="服务区域"
    width="640px"
    :isShow="isShow"
    @close="close"
    :isShowFooter="false"
  >
    <div class="select_box">
      <el-select
        v-model="province"
        filterable
        clearable
        placeholder="请选择省"
        size="small"
        @change="provinceChange"
      >
        <el-option
          v-for="item in provinceList"
          :key="item.areaCode"
          :label="item.areaName"
          :value="item.areaCode"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="city"
        filterable
        clearable
        placeholder="请选择市"
        size="small"
        @change="cityChange"
      >
        <el-option
          v-for="item in cityList"
          :key="item.areaCode"
          :label="item.areaName"
          :value="item.areaCode"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="region"
        filterable
        clearable
        placeholder="请选择区"
        size="small"
      >
        <el-option
          v-for="item in regionList"
          :key="item.areaCode"
          :label="item.areaName"
          :value="item.areaCode"
        >
        </el-option>
      </el-select>
      <div>
        <btnComp btnType="text" @click="add">添加</btnComp>
      </div>
    </div>
    <div class="select_con">
      <el-tag v-for="tag in tags" :key="tag.id" closable @close="closeTag(tag.id)">
        {{ tag.name }}
      </el-tag>
    </div>
  </dialogComp>
</template>

<script>
import { areaList, coverAreaAdd, coverAreaList, coverAreaDel } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tags: [],
      province: '',
      city: '',
      region: '',
      provinceList: [],
      cityList: [],
      regionList: []
    }
  },
  watch: {
    isShow (val) {
      val && this.getCoverAreaList()
      val && this.getAreaList(2, null)
    }
  },
  methods: {
    getCoverAreaList () {
      const params = {
        serviceStationId: this.info.id,
        masterStationId: this.info.masterStationId
      }
      coverAreaList(params).then(res => {
        if (res.code === '1') {
          this.tags = res.data.map(item => ({ id: item.id, name: item.provinceCityRegion }))
        }
      })
    },
    getAreaList (level, code) {
      areaList({ level, code }).then(res => {
        if (res.code === '1') {
          switch (level) {
            case 2:
              this.provinceList = res.data
              break
            case 3:
              this.cityList = res.data
              break
            case 4:
              this.regionList = res.data
              break
            default:
              break
          }
        }
      })
    },
    provinceChange (val) {
      this.city = ''
      this.region = ''
      this.getAreaList(3, val)
    },
    cityChange (val) {
      this.region = ''
      this.getAreaList(4, val)
    },
    add () {
      if (!this.province) {
        this.$message.error('至少选择一个省份')
        return
      }
      const params = {
        serviceStationId: this.info.id,
        masterStationId: this.info.masterStationId,
        province: this.province,
        city: this.city,
        region: this.region
      }
      coverAreaAdd(params).then(res => {
        if (res.code === '1') {
          let { provinceName = '', cityName = '', regionName = '' } = res.data
          cityName = cityName === null ? '' : ',' + cityName
          regionName = regionName === null ? '' : ',' + regionName
          this.tags.push({
            id: res.data.id,
            name: provinceName + cityName + regionName
          })
        }
      })
    },
    // 删除tag
    closeTag (id) {
      coverAreaDel({ id }).then(res => {
        if (res.code === '1') {
          this.$message.success('删除成功')
          this.getCoverAreaList()
        }
      })
    },
    close () {
      this.tags = []
      this.province = ''
      this.city = ''
      this.region = ''
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.select_box {
  display: flex;
  align-items: center;
  > div {
    margin-right: 8px;
  }
}
.select_con {
  margin-top: 20px;
  .el-tag {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
.item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  > div {
    margin-right: 8px;
  }
  .btn {
    font-size: 20px;
    cursor: pointer;
    &.add {
      margin-right: 8px;
    }
  }
}
</style>
