<template>
  <dialogComp
    :title="titleD"
    width="640px"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="110px"
    >
      <div class="flex">
        <inputFormComp
          label="姓名:"
          prop="name"
          :hval="formData.name"
          @blur="nameBlur"
          placeholder="请输入姓名"
        />
        <inputFormComp
          label="手机号/固话:"
          prop="mobile"
          :hval="formData.mobile"
          @blur="mobileBlur"
          placeholder="请输入手机号/固话"
        />
      </div>
      <div class="flex">
        <selectFormComp
          label="角色:"
          prop="businessRole"
          placeholder="请选择角色"
          :data="businessRoleList"
          :hval="formData.businessRole"
          @change="businessRoleChange"
        />
        <!-- <inputFormComp
          label="接单量:"
          prop="orderNumber"
          inputType="number"
          :hval="formData.orderNumber"
          @blur="orderNumberBlur"
          placeholder="请输入接单量"
        /> -->
        <radioFormComp
          label="性别:"
          prop="sex"
          style="width: 55%"
          :radioList="sexList"
          :hval="formData.sex"
          @change="sexChange"
        />
      </div>
      <div class="flex">

        <radioFormComp
          label="微信认证:"
          prop="wechatAuthentication"
          :radioList="wechatList"
          :hval="formData.wechatAuthentication"
          @change="wechatChange"
        />
      </div>
    </el-form>
  </dialogComp>
</template>
<script>
import { serStatPeoAdd, serStatPeoEdit } from '@/api'
export default {
  name: '',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data () {
    const checkMobile = (rule, val, cb) => {
      var telReg = /^1[3456789]\d{9}$/
      var phoneReg = new RegExp(/^\d{3}-\d{6,8}|\d{4}-\d{6,8}$/)
      if (val.includes('-')) {
        if (phoneReg.test(val)) {
          return cb()
        } else {
          cb(new Error('固话必须用"-"连接,区号3-4位,号码为6-8位'))
        }
      } else {
        if (telReg.test(val)) {
          return cb()
        } else {
          cb(new Error('手机号码格式不正确'))
        }
      }
    }
    return {
      businessRoleList: [
        { label: '默认负责人', value: 1 },
        { label: '负责人', value: 2 },
        { label: '成员', value: 3 }
      ],
      sexList: [
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ],
      wechatList: [
        { label: '未认证', value: false },
        { label: '已认证', value: true }
      ],
      formData: {
        name: '',
        mobile: '',
        businessRole: '',
        orderNumber: '',
        sex: 1,
        wechatAuthentication: false
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        businessRole: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        orderNumber: [
          { required: true, message: '请输入接单量', trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        wechatAuthentication: [
          { required: true, message: '请选择微信是否认证', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    titleD () {
      return this.title === 'add' ? '新增人员' : '编辑人员'
    }
  },
  watch: {
    isShow (val) {
      if (val && this.title === 'edit') {
        const {
          name,
          mobile,
          businessRole,
          sex,
          wechatAuthentication,
          orderNumber
        } = this.info
        this.formData.name = name
        this.formData.mobile = mobile
        this.formData.businessRole = businessRole
        this.formData.orderNumber = orderNumber
        this.formData.sex = sex
        this.formData.wechatAuthentication = wechatAuthentication
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    nameBlur (val) {
      this.formData.name = val
    },
    mobileBlur (val) {
      this.formData.mobile = val
    },
    businessRoleChange (val) {
      this.formData.businessRole = val
    },
    orderNumberBlur (val) {
      this.formData.orderNumber = val
    },
    sexChange (val) {
      this.formData.sex = val
    },
    wechatChange (val) {
      this.formData.wechatAuthentication = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const params = {
            ...this.formData,
            serviceStationId: this.$route.query.id
          }
          if (this.title === 'add') {
            serStatPeoAdd(params).then((res) => {
              if (res.code === '1') {
                this.$message.success('添加成功')
                this.close()
              }
            })
          } else {
            serStatPeoEdit({ ...params, id: this.info.id }).then((res) => {
              if (res.code === '1') {
                this.$message.success('编辑成功')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
