<template>
  <div>
    <el-select
      v-model="storeName"
      placeholder="商家名模糊搜索"
      filterable
      size="small"
      reserve-keyword
      :remote-method="remoteMethodToStore"
      remote
      clearable
      @change="storeNameChange"
      :disabled="disabled"
    >
      <el-option
        v-for="item in storeNameList"
        :key="item.partnerId"
        :label="item.partnerName"
        :value="item.partnerId"
      ></el-option>
    </el-select>
    <btnComp
      v-if="showRankBtn"
      btnType="text"
      style="font-size:14px;margin-left:10px"
      @click="rankClick"
      >商家排名</btnComp
    >
  </div>
</template>
<script>
import { partnerInfoLists } from '@/api'
export default {
  props: {
    hval: {},
    disabled: {
      type: Boolean,
      default: false
    },
    showRankBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      storeName: '',
      storeNameList: []
    }
  },
  watch: {
    hval: {
      handler (val) {
        this.storeName = val
      }
    }
  },
  mounted () {},
  methods: {
    // 商家下拉列表
    getstoreNameList (partnerName) {
      partnerInfoLists({ page: 1, size: 1000, partnerName }).then(res => {
        if (res.code === 0) {
          this.storeNameList = res.result.records
        }
      })
    },
    // 远程搜索
    remoteMethodToStore (query) {
      this.getstoreNameList(query)
    },
    // 商家选择
    storeNameChange (val) {
      let item = {}
      if (val) {
        const { partnerName, partnerId } = this.storeNameList.find(item => item.partnerId === val)
        item = { partnerName, partnerId }
      } else {
        // 点击清空按钮
        item = {
          partnerName: '',
          partnerId: ''
        }
      }
      this.$emit('storeNameChange', item)
    },
    rankClick () {
      this.$emit('rankClick')
    }
  }
}
</script>
