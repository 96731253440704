
export default {
  data () {
    return {
      // 开票类型:
      billingTypeList: [
        { label: '自开发票', value: 1 },
        { label: '代开发票', value: 2 }
      ],
      // 结算周期
      settlementCycleList: [
        { label: '月结', value: 1 },
        { label: '周结', value: 2 },
        { label: '单结', value: 3 }
      ],
      // 合作类型
      cooperationModeList: [
        { label: '签约', value: 1 },
        { label: '加盟', value: 2 },
        { label: '商家', value: 3 },
        { label: '自营', value: 4 },
        { label: '个人', value: 5 }
      ],
      // 是否连锁
      chainList: [
        { label: '非连锁', value: 1 },
        { label: '主站', value: 2 },
        { label: '副站', value: 3 }
      ],
      // 接单状态
      statusList: [
        { label: '接单中', value: 1 },
        { label: '暂停接单', value: 2 },
        { label: '解除合作', value: 3 }
      ],
      // 财务审核状态
      auditStatusList: [
        { label: '待审核', value: 1 },
        { label: '待结算', value: 2 },
        { label: '已结算', value: 3 }
      ],
      // 财务审核状态
      payStatusList: [
        { label: '待付款', value: 1 },
        { label: '已付款', value: 2 }
      ],
      // 账号类型
      accountTypeList: [
        { label: '公司账号', value: 1 },
        { label: '私人账号', value: 2 }
      ],
      // 收款账号
      collectionTypeList: [
        { label: '银行卡号', value: 1 },
        { label: '支付宝账号', value: 2 }
      ],
      // // 是否连锁
      // chainList: [
      //   { label: '是', value: true },
      //   { label: '不是', value: false }
      // ],
      // 信息是否不全
      infoList: [
        { label: '全', value: true },
        { label: '不全', value: false }
      ]
    }
  }
}
