<template>
  <ul class="img_list">
    <template v-if="!isSingle">
      <li
        v-for="(item, index) in imageListNew"
        :key="index"
        @click.prevent="handlePictureCardPreview(item.url)"
      >
        <img :src="item.url" alt="" width="100%" height="100%" />
        <div
          class="del_icon"
          @click.stop="delPic(item.id, item.catelog, index)"
        >
          <!-- <i class="el-icon-delete-solid"></i> -->
          删除
        </div>
      </li>
    </template>
    <template v-if="imageListNew.url">
      <li
        :class="{ cardSty: isCard }"
        @click.prevent="handlePictureCardPreview(imageListNew.url)"
      >
        <img
          :src="imageListNew && imageListNew.url"
          alt=""
          width="100%"
          height="100%"
        />
        <div
          class="del_icon"
          @click.stop="delPic(imageListNew.id, imageListNew.catelog)"
        >
          <!-- <i class="el-icon-delete-solid">删除</i> -->
          删除
        </div>
      </li>
    </template>

    <li :class="{ cardSty: isCard }">
      <el-upload
        class="upload"
        :class="[front,back]"
        :show-file-list="false"
        action=""
        :multiple="multiple"
        :http-request="uploadpic"
        :before-upload="beforeUpload"
      >
        <!-- <i class="el-icon-circle-plus"></i> -->
        <i class="iconfont iconshangchuan"></i>
        <span class="hint">上传图片</span>
      </el-upload>
    </li>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </ul>
</template>
<script>
import { client, catelogImg, catelogImgTest, catelogVideoTest, catelogVideo, ossURL, uuid } from '@/utils/alioss'
import { faultImgAdd, faultImgDel } from '@/api'
export default {
  props: {
    /* 父模块名，ServiceStation：服务站图片
     * 子模块名
     * businessLicense:营业执照
     * cooperationAgreement:合作协议
     * idcardPositive:身份证（正面）
     * idcardBack:身份证（反面） */
    funcCode: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否是身份证
    isCard: {
      type: Boolean,
      default: false
    },
    // 身份证正面
    front: {
      type: String,
      default: ''
    },
    // 身份证反面
    back: {
      type: String,
      default: ''
    },
    businessLicense: {
      type: Object,
      default: () => {}
    },
    idcardPositive: {
      type: Object,
      default: () => {}
    },
    idcardBack: {
      type: Object,
      default: () => {}
    },
    cooperationAgreement: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      imageListNew: [],
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  watch: {
    businessLicense: {
      handler (val) {
        this.commonWatchFn(val)
      }
    },
    idcardPositive: {
      handler (val) {
        this.commonWatchFn(val)
      }
    },
    idcardBack: {
      handler (val) {
        this.commonWatchFn(val)
      }
    },
    cooperationAgreement: {
      handler (val) {
        if (val) {
          this.imageListNew = val.map(item => ({
            url: ossURL + item.filePath,
            catelog: item.filePath,
            id: item.id
          }))
        }
      }
    }
  },
  computed: {
    isSingle () {
      // 标识是个可以上传多张图片
      return this.funcCode !== 'cooperationAgreement'
    }
  },
  mounted () {},
  methods: {
    commonWatchFn (val) {
      if (val) {
        const { filePath, id } = val
        this.imageListNew = {
          url: ossURL + filePath,
          catelog: filePath,
          id: id
        }
      }
    },
    // 上传前
    beforeUpload (file) {
      if (this.isSingle) {
        if (this.imageListNew.url) {
          this.$message.error('只能上传一张')
          return false
        }
      }
      const index = file.name.lastIndexOf('.')
      const suffix = file.name.substring(index + 1)
      this.suffix = suffix // 后缀名
      const reg = /(jpg|jpeg|png)/i
      if (!reg.test(suffix)) {
        this.$message.error('请上传jpg或png格式的图片')
        return false
      }
      return true
    },
    // 上传图片
    uploadpic (file) {
      this.save(file)
    },
    // 删除图片 先删除数据库  在删除oss存储
    delPic (id, catelog, index) {
      faultImgDel({ id: id }).then(res => {
        if (res.code === '1') {
          client
            .delete(catelog)
            .then(res => {
              if (this.isSingle) {
                this.imageListNew = { url: '', catelog: '', id: '' }
              } else {
                this.imageListNew.splice(index, 1)
              }
              this.$emit('reload')
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    // 保存
    save (file) {
      const fileName = uuid() + '.' + this.suffix // 生成文件名（file.file.name）
      // 判断存储到哪个目录下 不同的环境存到不同的文件夹中
      let catelog = ''
      if (this.isProduction) {
        if (this.suffix.toLowerCase() === 'mp4') {
          catelog = catelogVideo
        } else {
          catelog = catelogImg
        }
      } else {
        if (this.suffix.toLowerCase() === 'mp4') {
          catelog = catelogVideoTest
        } else {
          catelog = catelogImgTest
        }
      }
      const params = {
        recordId: this.id,
        filePath: catelog + fileName,
        moduleCode: 'ServiceStation',
        funcCode: this.funcCode
      }
      faultImgAdd(params).then(res => {
        if (res.code === '1') {
          client
            .put(catelog + fileName, file.file)
            .then(res2 => {
              if (this.isSingle) {
                this.imageListNew = {
                  url: res2.url,
                  catelog: catelog + fileName,
                  id: res.id
                }
              } else {
                this.imageListNew.push({
                  url: res2.url,
                  catelog: catelog + fileName
                })
              }
              // 新增成功刷新列表
              this.$emit('reload')
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    handlePictureCardPreview (url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-upload {
  width: 100%;
  height: 100%;
}
.img_list {
  display: flex;
  li {
    position: relative;
    width: 126px;
    height: 156px;
    line-height: 156px;
    background: rgba(232, 236, 255, 0.5);
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    // padding: 10px;
    margin-right: 16px;
    text-align: center;
    cursor: pointer;
    &.active {
      border-color: #385bff;
    }
    &.cardSty {
      width: 126px;
      height: 80px;
      .upload {
        width: 100%;
        height: 100%;
        color: #fff;
        .iconshangchuan {
          position: relative;
          top: -40px;
          color: #fff;
        }
        .hint {
          height: 26px;
          position: absolute;
          left: 34px;
          top: -20px;
          color: #fff;
        }
      }
    }
    .el-icon-circle-plus {
      font-size: 30px;
      color: rgba(51, 51, 51, 0.3);
    }
    .del_icon {
      position: absolute;
      width: 126px;
      left: 0;
      bottom: 0;
      // width: 22px;
      height: 28px;
      line-height: 28px;
      background: rgba(0, 0, 0, 0.32);
      border-radius: 4px 0px 3px 0px;
      cursor: pointer;
      color: #fff;
    }
    // .el-icon-delete-solid {
    //   color: #fff;
    //   position: absolute;
    //   right: 3px;
    //   bottom: 3px;
    // }
  }
  .upload {
    .el-upload--picture-card {
      width: 102px;
      height: 102px;
      line-height: 102px;
    }
    .iconshangchuan {
      font-size: 20px;
      color: #385bff;
    }
    .hint {
      position: absolute;
      left: 35px;
      top: 20px;
      color: rgba(38, 38, 38, 0.8);
    }
    &.front {
      background: url('~@/assets/images/renxiang.png') ;
      background-color:  rgba(0, 0, 0, 0.32);
      background-size: contain;
    }
    &.back {
      background: url('~@/assets/images/guohui.png');
      background-color:  rgba(0, 0, 0, 0.32);
      background-size: contain;
    }
  }
}
</style>
