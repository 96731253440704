<template>
  <div class="layout_common station_info_detail">
    <tabsComp :tabsList="tabsList" @change="tabsChange"/>
    <baseInfo v-if="active === '1'" />
    <peopleInfo v-if="active === '2'" />
  </div>
</template>
<script>
import baseInfo from './base-info'
import peopleInfo from './people-info'
export default {
  components: { baseInfo, peopleInfo },
  data () {
    return {
      active: '1',
      tabsList: [
        {
          name: '1',
          label: '基本信息'
        },
        {
          name: '2',
          label: '人员信息'
        }
      ]
    }
  },
  methods: {
    tabsChange (val) {
      this.active = val
    }
  }
}
</script>
