<template>
  <div class="layout_common people_info">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp placeholder="姓名" :hval="name" @blur="nameBlur" />
            <selectComp
              placeholder="角色"
              :data="businessRoleList"
              :hval="businessRole"
              @change="businessRoleChange"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="add('add')" v-permission="'新增人员'">新 增</btnComp>
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchHandle('批量删除', 2)"
               v-permission="'批量删除人员'"
              >批量删除</btnComp
            >
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          isShowCheckBox
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          :selectEnable="selectEnable"
          @select="select"
          @selectAll="selectAll"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="wechatAuthentication" label="微信认证">
            <template v-slot="scope">
              {{ scope.row.wechatAuthentication ? "已认证" : "未认证" }}
            </template>
          </el-table-column>
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="add('edit', scope.row)"
                v-if="scope.row.ascription !== 1"
               v-permission="'编辑人员'"
                >编辑</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
        <add :isShow="addShow" @close="close" :title="title" :info="info" />
        <dialogComp
          title="提示"
          :isShow="hintShow"
          @close="close"
          @sure="hintSure"
        >
          <p>{{ `确定${hintTitle}吗？` }}</p>
        </dialogComp>
      </div>
    </div>
  </div>
</template>
<script>
import add from './add'
import { serStatPeoList, serStatPeoUpdateStatus } from '@/api'
export default {
  name: '',
  props: {},
  components: { add },
  data () {
    return {
      addShow: false,
      hintShow: false,
      selectIds: [],
      hintTitle: '',
      businessRoleList: [
        { label: '默认负责人', value: 1 },
        { label: '负责人', value: 2 },
        { label: '成员', value: 3 }
      ],
      curPage: 1,
      pageSize: 10,
      total: 0,
      name: '',
      businessRole: '',
      title: '',
      info: {},
      tableData: [],
      theadName: [
        { prop: 'name', label: '姓名' },
        { prop: 'ascriptionName', label: '归属人员' },
        { prop: 'businessRoleName', label: '角色' },
        { prop: 'mobile', label: '手机号' },
        { slot: 'wechatAuthentication' },
        { prop: 'sexName', label: '性别' },
        { prop: 'orderNumber', label: '接单量' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    }
  },
  watch: {},
  created () {},
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        serviceStationId: this.$route.query.id,
        name: this.name,
        businessRole: this.businessRole
      }
      serStatPeoList(params).then((res) => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    // 归属为主站的话  不能选择
    selectEnable (row) {
      if (row.ascription === 1) {
        return false
      }
      return true
    },
    nameBlur (val) {
      this.name = val
    },
    businessRoleChange (val) {
      this.businessRole = val
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    add (val, info) {
      this.title = val
      if (val === 'edit') {
        this.info = info
      }
      this.addShow = true
    },
    select (val) {
      this.selectIds = val.map((item) => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map((item) => item.id)
    },
    batchHandle (val, status) {
      this.hintTitle = val
      this.batchHandlestatus = status
      this.hintShow = true
    },
    hintSure () {
      const params = {
        ids: this.selectIds,
        status: this.batchHandlestatus
      }
      serStatPeoUpdateStatus(params).then((res) => {
        if (res.code === '1') {
          this.$message.success(`${this.hintTitle}成功`)
          this.close()
        }
      })
    },
    close () {
      this.selectIds = []
      this.addShow = false
      this.hintShow = false
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.name = ''
      this.mobile = ''
      this.businessRole = ''
      this.status = ''
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
