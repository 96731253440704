<template>
  <div class="main_box base_info">

    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="150px"
    >
      <div class="base_info_con bgc">
        <div class="title">基本信息</div>
        <btnComp class="btn" @click="save" v-permission="'保存'" >保 存</btnComp>
        <div class="conPadding">
          <div class="item flex">
            <inputFormComp
              label="服务站名称"
              prop="name"
              :hval="formData.name"
              @blur="nameBlur"
              placeholder="请输入服务站名称"
            />
            <selectFormComp
              label="合作类型"
              prop="cooperationMode"
              placeholder="请选择合作类型"
              :data="cooperationModeList"
              :hval="formData.cooperationMode"
              @change="cooperationModeChange"
            />
            <el-form-item label="商家:" :prop="`${isSelectPartner?'storeName':''}`">
              <merchant :hval="formData.storeName" @storeNameChange="storeNameChange" />
            </el-form-item>
          </div>
          <div class="item flex">
            <selectFormComp
              label="接单状态"
              prop="status"
              placeholder="请选择接单状态"
              :data="statusList"
              :hval="formData.status"
              @change="statusChange"
            />
            <el-form-item label="区域" prop="area">
              <areaData :hval="formData.area" @change="areaChange" />
            </el-form-item>
            <inputFormComp
              label="详细地址"
              prop="detailAddress"
              :hval="formData.detailAddress"
              @blur="detailAddressBlur"
              placeholder="请输入详细地址"
            />
          </div>
          <div class="item flex">
             <selectFormComp
                label="是否连锁"
                prop="chain"
                placeholder="请选择是否连锁"
                :data="chainList"
                :hval="formData.chain"
                @change="chainChange"
              />
              <el-form-item v-if="formData.chain === 3" label="主站" prop="masterStationId">
                <el-select
                  v-model="formData.masterStationName"
                  placeholder="主站模糊搜索"
                  filterable
                  size="small"
                  reserve-keyword
                  :remote-method="remoteMethod"
                  remote
                  @change="masterStationChange"
                >
                  <el-option
                    v-for="item in masterStationList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-else></el-form-item>
              <el-form-item>
                <btnComp style="margin-top:32px" @click="coverArea" v-permission="'服务区域'">服务区域</btnComp>
              </el-form-item>
          </div>
        </div>
      </div>
      <div class="finance_info bgc" v-if="$route.query.chain !== 3">
        <div class="title">财务信息</div>
        <div class="conPadding">
          <div class="item flex">
            <selectFormComp
              label="开票类型"
              prop="billingType"
              placeholder="请选择开票类型"
              :data="billingTypeList"
              :hval="formData.billingType"
              @change="billingTypeChange"
            />
            <selectFormComp
              label="账号类型"
              prop="accountType"
              disabled
              placeholder="请选择账号类型"
              :data="accountTypeList"
              :hval="formData.accountType"
            />
            <inputFormComp
              label="税点:"
              prop="tax"
              disabled
              :hval="formData.tax"
            />
          </div>
          <div class="item flex">
            <selectFormComp
              label="收款账号"
              prop="collectionType"
              :data="collectionTypeList"
              :hval="formData.collectionType"
              @change="collectionTypeChange"
            />
            <selectFormComp
              label="结算周期"
              prop="settlementCycle"
              placeholder="请选择结算周期"
              :data="settlementCycleList"
              :hval="formData.settlementCycle"
              @change="settlementCycleChange"
            />
            <inputFormComp
              label="收款银行"
              prop="bank"
              :hval="formData.bank"
              @blur="bankBlur"
              placeholder="请输入收款银行"
            />
          </div>
          <div class="item flex">
            <inputFormComp
              label="开户支行"
              prop="branch"
              :hval="formData.branch"
              @blur="branchBlur"
              placeholder="请输入开户支行"
            />
            <inputFormComp
              label="开户公司/姓名"
              prop="accountName"
              :hval="formData.accountName"
              @blur="accountNameBlur"
              placeholder="请输入开户公司/姓名"
            />
            <inputFormComp
              label="开户账号/卡号"
              prop="cardNumber"
              :hval="formData.cardNumber"
              @blur="cardNumberBlur"
              placeholder="请输入开户账号/卡号"
            />
          </div>
        </div>
      </div>
      <div class="business_license bgc">
        <p class="title">营业执照</p>
        <ul class="conPadding">
          <li>
            <div class="ctitle">营业执照</div>
            <uploadComp
              funcCode="businessLicense"
              :id="formData.id"
              :businessLicense="businessLicense"
              @reload="reload"
            />
          </li>
          <li>
            <div class="ctitle">合作协议</div>
            <uploadComp
              multiple
              funcCode="cooperationAgreement"
              :id="formData.id"
              :cooperationAgreement="cooperationAgreement"
              @reload="reload"
            />
          </li>
          <li>
            <div class="ctitle">身份证正面</div>
            <uploadComp
              funcCode="idcardPositive"
              :id="formData.id"
              :idcardPositive="idcardPositive"
              @reload="reload"
              :isCard="true"
              front="front"
            />
          </li>
          <li>
            <div class="ctitle">身份证反面</div>
            <uploadComp
              funcCode="idcardBack"
              :id="formData.id"
              :idcardBack="idcardBack"
              @reload="reload"
              :isCard="true"
              back="back"
            />
          </li>
        </ul>
      </div>
    </el-form>
    <coverArea :isShow="coverAreaShow" @close="close" :info="formData" />
  </div>
</template>
<script>
import coverArea from './coverArea'
import areaData from '@/views/components/areaDataNew'
import uploadComp from '@/views/components/uploadComp'
import merchant from '@/views/components/merchant'
import constList from '@/mixin/constList'
import { serviceStatDeatil, serviceStatUpdate, serviceStatList } from '@/api'
import { handleArea } from '@/utils/common'
export default {
  name: '',
  props: {},
  components: { areaData, uploadComp, coverArea, merchant },
  mixins: [constList],
  data () {
    return {
      coverAreaShow: false,
      masterStationList: [],
      storeNameList: [],
      businessLicense: {},
      cooperationAgreement: [],
      idcardPositive: {},
      idcardBack: {},
      formData: {
        id: '',
        name: '',
        cooperationMode: '',
        detailAddress: '',
        status: '',
        area: [],
        province: '',
        city: '',
        region: '',
        billingType: '',
        settlementCycle: '',
        accountType: '',
        collectionType: '',
        tax: '',
        bank: '',
        branch: '',
        accountName: '',
        cardNumber: '',
        chain: '',
        masterStationId: '',
        masterStationName: '',
        storeId: '',
        storeName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入服务站名称', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '请选择区域', trigger: 'blur' }
        ],
        chain: [
          { required: true, message: '请选择是否连锁', trigger: 'change' }
        ],
        cooperationMode: [
          { required: true, message: '请选择合作类型', trigger: 'change' }
        ],
        detailAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        billingType: [
          { required: true, message: '请选择开票类型', trigger: 'change' }
        ],
        tax: [{ required: true, message: '请输入税点', trigger: 'blur' }],
        accountType: [
          { required: true, message: '请选择账号类型', trigger: 'change' }
        ],
        collectionType: [
          { required: true, message: '请选择收款账号', trigger: 'change' }
        ],
        settlementCycle: [
          { required: true, message: '请选择结算周期', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择接单状态', trigger: 'change' }
        ],
        bank: [{ required: true, message: '请输入收款银行', trigger: 'blur' }],
        branch: [
          { required: true, message: '请输入开户支行', trigger: 'blur' }
        ],
        accountName: [
          { required: true, message: '请输入开户公司/姓名', trigger: 'blur' }
        ],
        cardNumber: [
          { required: true, message: '请输入开户账号/卡号', trigger: 'blur' }
        ],
        storeName: [
          { required: true, message: '请选择商家', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isSelectPartner () { // 合作类型是否选择商家
      return this.formData.cooperationMode === 3
    }
  },
  created () {
    this.getDetail()
  },
  mounted () {},
  methods: {
    // 图片上传后重新请求详情接口
    reload () {
      this.getDetail()
    },
    getDetail () {
      serviceStatDeatil({ id: this.$route.query.id }).then(res => {
        if (res.code === '1') {
          const {
            id,
            name,
            cooperationMode,
            detailAddress,
            billingType,
            tax,
            settlementCycle,
            status,
            accountType,
            collectionType,
            bank,
            branch,
            accountName,
            cardNumber,
            province,
            city,
            region,
            businessLicense,
            cooperationAgreement,
            idcardPositive,
            idcardBack,
            masterStationId,
            masterStationName,
            chain,
            storeName,
            storeId
          } = res.data
          // 获取图片数据
          /* businessLicense:营业执照
           * cooperationAgreement:合作协议
           * idcardPositive:身份证（正面）
           * idcardBack:身份证（反面） */
          this.businessLicense = businessLicense
          this.cooperationAgreement = cooperationAgreement
          this.idcardPositive = idcardPositive
          this.idcardBack = idcardBack
          // 获取基本信息
          this.formData.id = id
          this.formData.name = name
          this.formData.cooperationMode = cooperationMode
          this.formData.detailAddress = detailAddress
          this.formData.status = status
          this.formData.accountType = accountType
          this.formData.collectionType = collectionType
          this.formData.status = status
          this.formData.billingType = billingType
          this.formData.tax = tax
          this.formData.settlementCycle = settlementCycle
          this.formData.bank = bank
          this.formData.branch = branch
          this.formData.accountName = accountName
          this.formData.cardNumber = cardNumber
          this.formData.masterStationId = masterStationId
          this.formData.masterStationName = masterStationName
          this.formData.chain = chain
          this.formData.storeName = storeName
          this.formData.storeId = storeId
          this.formData.area = handleArea(province, city, region)
          const [a, b, c] = this.formData.area
          this.formData.province = a || ''
          this.formData.city = b || ''
          this.formData.region = c || ''
        }
      })
    },
    getServiceStatList (name) {
      serviceStatList({ page: 1, size: 100, chain: 2, name }).then(res => {
        if (res.code === '1') {
          this.masterStationList = res.data.list
        }
      })
    },
    // 远程搜索
    remoteMethod (query) {
      this.getServiceStatList(query)
    },
    // 服务站选择
    masterStationChange (val) {
      this.formData.masterStationId = val
    },
    // 商家选择
    storeNameChange (val) {
      this.formData.storeId = val.partnerId
      this.formData.storeName = val.partnerName
    },
    // 服务区域
    coverArea () {
      this.coverAreaShow = true
    },
    close () {
      this.coverAreaShow = false
    },
    nameBlur (val) {
      this.formData.name = val
    },
    cooperationModeChange (val) {
      this.formData.cooperationMode = val
    },
    statusChange (val) {
      this.formData.status = val
    },
    areaChange (val) {
      this.formData.area = val
      const [a, b, c] = val
      this.formData.province = a || ''
      this.formData.city = b || ''
      this.formData.region = c || ''
    },
    detailAddressBlur (val) {
      this.formData.detailAddress = val
    },
    chainChange (val) {
      this.formData.chain = val
      this.formData.masterStationId = ''
      this.formData.masterStationName = ''
    },
    billingTypeChange (val) {
      this.formData.billingType = val
      this.formData.accountType = val
      if (val === 1) {
        this.formData.tax = 0
      } else {
        this.formData.tax = 7
      }
    },
    collectionTypeChange (val) {
      this.formData.collectionType = val
    },
    settlementCycleChange (val) {
      this.formData.settlementCycle = val
    },
    bankBlur (val) {
      this.formData.bank = val
    },
    branchBlur (val) {
      this.formData.branch = val
    },
    accountNameBlur (val) {
      this.formData.accountName = val
    },
    cardNumberBlur (val) {
      this.formData.cardNumber = val
    },
    save () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          serviceStatUpdate(this.formData).then(res => {
            if (res.code === '1') {
              this.$message.success('保存成功')
              this.$router.push({ name: 'station-info' })
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bgc {
  background-color: #fff;
  margin-bottom: 24px;
}
.conPadding {
  padding: 24px 40px 28px 32px;
}
.title {
  height: 55px;
  line-height: 55px;
  padding: 0 32px;
  border-bottom: 1px solid #e9e9e9;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.base_info {
  .base_info_con {
    position: relative;
    .btn {
      position: absolute;
      right: 82px;
      top: 12px;
    }
  }

  .business_license {
    ul {
      padding-left: 40px;
      li {
        display: flex;
        margin-top: 20px;
        .ctitle {
          width: 80px;
          text-align: left;
        }
      }
    }
  }
  .item {
    ::v-deep .el-form-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 40px;
      .el-form-item__label {
        line-height: 30px;
        text-align: left;
      }
      .el-form-item__content {
        margin-left: 0 !important;
      }
      .el-date-editor {
        width: 100%;
      }
    }
  }
}
</style>
